/**
 * @desc 删除
 */

import fetcher from 'common/lib/fetcher'
import { MyAxiosRequestConfig } from 'common/interface/crud'

export type CustomerCollectDelByBusinessParams = Partial<{
  /** businessId */
  businessId?: string
  /** collectType */
  collectType?:
    | 'GOODS_COLLECT'
    | 'GS_GOODS_COLLECT'
    | 'CUSTOMER_COLLECT'
    | 'DICT_COLLECT_ACCOUNTING_PRODUCTS'
    | 'DICT_COLLECT_CASH_FLOW'
    | 'DICT_COLLECT_PRE_CASH_FLOW'
}>

export type CustomerCollectDelByBusinessResponse = boolean

export const customerCollectDelByBusinessMethod = 'DELETE'

export const customerCollectDelByBusinessUrl =
  '/hsu/v1/customer/collect/delByBusiness'

export function customerCollectDelByBusinessRequest(
  params?: CustomerCollectDelByBusinessParams,
  options?: MyAxiosRequestConfig
) {
  return fetcher.request<CustomerCollectDelByBusinessResponse>({
    url: customerCollectDelByBusinessUrl,
    method: customerCollectDelByBusinessMethod,
    params,
    ...options
  })
}

customerCollectDelByBusinessRequest.method = customerCollectDelByBusinessMethod
customerCollectDelByBusinessRequest.url = customerCollectDelByBusinessUrl
