/**
 * 物资类别页面上的操作按钮
 */

import { OptionItem } from 'common/router/interface'

export const TREE_DICT_CREATE_BTN = 'treeDictCreateBtn'

export const TREE_DICT_UPDATE_BTN = 'treeDictUpdateBtn'

export const TREE_DICT_DELETE_BTN = 'treeDictDeleteBtn'

export const TREE_DICT_CREATE_OPTION: OptionItem = {
  key: TREE_DICT_CREATE_BTN,
  name: '添加类别'
}

export const TREE_DICT_UPDATE_OPTION: OptionItem = {
  key: TREE_DICT_UPDATE_BTN,
  name: '编辑类别'
}

export const TREE_DICT_DELETE_OPTION: OptionItem = {
  key: TREE_DICT_DELETE_BTN,
  name: '删除类别'
}
